<template>
  <!-- 办理入职页面 -->
  <div class="applyEntry">
    <van-nav-bar
      title="办理入职"
      left-text=""
      right-text="入职记录"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="rzHeader">
      <div class="h_left">
        <svg-icon icon-class="applyEntry1"></svg-icon>
        <span>添加待入职</span>
      </div>
      <div class="h_right" @click="somaEntry">
        <svg-icon icon-class="applyEntry2"></svg-icon>
        <span>扫码入职</span>
      </div>
    </div>
    <ul v-if="entryList.length>0">
      <li v-for="(item,index) in entryList" :key="index" @click="toInfo">
         <div class="zp">
            <img src="@/assets/img/interview_pic1.png" alt="" />
          </div>
        <div class="row1">
          <span>{{item.name}}</span>
          <svg-icon icon-class="induction_link"></svg-icon>
          <span>{{item.gw}}</span>
        </div>
        <div class="row2">
          <span>{{item.tel}}</span>
          <svg-icon icon-class="interview_tel"></svg-icon>
          <svg-icon icon-class="interview_wx"></svg-icon>
        </div>
        <div class="row3">
          <span>{{item.jy}}</span>
          <span>{{item.xl}}</span>
        </div>
        <div class="row4">
          <div class="row4_left">
            <span>到岗时间：</span>
            <span>{{item.dgsj}}</span>
          </div>
          <span @click="confirmEntry">确认入职</span>
        </div>
      </li>
    </ul>
   <div class="no_data" v-else>

    <svg-icon  class='kong' icon-class='applyEntry3'></svg-icon>
    <span>暂无待入职人员</span>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      entryList:[{name:'关先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'韦先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'吴先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'钟先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'曾先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'李先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'},{name:'黄先生',gw:'原材料仓管员',tel:'13595486227',jy:'3年经验',xl:'大专',dgsj:'2021-04-18 08:00'}]
    }
  },
  methods: {
    toInfo(){
      this.$router.push('/inductionInfo')
    },
    onClickLeft() {
      this.$router.push("/home");
    },
    onClickRight() {
      // 跳转到入职记录界面
      this.$router.push('/entryRecord')
    },
    // 扫码入职
    somaEntry(){
      this.$router.push('/saoEntry')
    },
    // 确认入职
    confirmEntry(){
      this.$router.push({path:'/confirmEntry',query:{fromType:'办理入职'}})
    }
  },
};
</script>

<style lang="less" scoped>
.applyEntry {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-size: 32px;
      }
    }
  }
  .rzHeader {
    display: flex;
    align-items: center;
    height: 294px;
    background: #fff;
    margin-bottom: 24px;
    .h_left,
    .h_right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 200px;
      flex: 1;
      .svg-icon {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
      }
      span {
        color: #333;
        font-size: 32px;
      }
    }
    .h_left {
      border-right: 1px solid #ccc;
    }
  }
  ul {
    height: calc(100% - 406px);
    overflow: auto;
    li {
      position: relative;
      height: 258px;
      padding: 0 30px;
      background: #fff;
      padding-top: 38px;
      margin-bottom: 24px;
      box-sizing: border-box;
      .zp{
        position: absolute;
        right:46px;
        top:38px;
        width:106px;
        height: 106px;
        img{
          width:100%;
          height:100%;
        }
      }
      .row1 {
        font-size: 40px;
        color: #000;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
       line-height: 40px;
        .svg-icon {
          // width: 24px;
          // height: 40px;
          font-size: 30px;
          line-height: 40px;
          margin:0 26px;
        }
   
      }
      .row2{
        font-size: 32px;
        color:#2B8DF0;
         margin-bottom: 18px;
           line-height: 32px;
        span{
          margin-right: 10px;
        }
        .svg-icon{
          font-size: 36px;
        }
        .svg-icon:nth-child(2){
          margin:0 18px;
        }
      }
      .row3{
        font-size: 28px;
        color:#666;
         margin-bottom: 20px;
        line-height: 28px;
        span:first-child{
          border-right:1px solid #999;
          padding-right: 14px;
          margin-right:14px;
        }
      }
      .row4{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color:#2B8DF0;
        font-size: 28px;
        line-height: 28px;
        >span{
          display: inline-block;
          box-sizing: border-box;
          width:138px;
          height: 48px;
            font-size: 28px;
          line-height: 48px;
          text-align: center;
          border: 1px solid #2B8DF0;
        }
      }
    }
  }
   .no_data{

    position: absolute;
    text-align: center;
      top:660px;
      left:50%;
      transform: translateX(-50%);
    .kong{
      width:332px;
      height: 228px;
    }
    span{
      display: block;
      color:#666;
      font-size: 32px;
    }
  }
}
</style>
